<template>
    <div class="navbarmobileFix">
        <div>

            <nav class=" d-none d-lg-block three-nav1">
                <ul class="three-nav1 navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
                    <li class="three-nav1" @click="accessReliableInformation()">
                        <div>
                            <img src="~@/assets/images/bookcreative.png" style="width: 40px;">
                        </div>
                    </li>
                    <li class="three-nav1" @click="searchClinic()">
                        <div>
                            <img src="~@/assets/images/hospitallens.png" style="width: 40px;">
                        </div>
                    </li>
                    <!-- <li class="three-nav1" @click="requestConsultation()">
                    <div>
                        <img src="~@/assets/images/hospitalplus.png" style="width: 40px;">
                    </div>
                </li> -->
                    <li class="three-nav1" @click="joinDoctorsCommunity()">
                        <div>
                            <img src="~@/assets/images/man-man.png" style="width: 40px;">
                        </div>
                    </li>

                </ul>
            </nav>
            <section class="banner-two2 img banner">
                <div class="container-fluid text-center text-align">
                    <div class="banner-padding">
                        <div class="heading-patient-groups">
                            <h1>Join patient groups</h1>
                        </div>
                        <!-- FOR DESKTOP  -->
                        <div class="text-align text-center banner-sub-content d-none d-lg-block">
                            <div class="joinPatientGroupText">
                                <h3 class="mb-0">Find strength in communities. Get answers to your health</h3>
                                <h3>queries. Overcome your fears and anxieties.</h3>

                                <h3 class="mt-4 mb-0">Learn how people with similar health conditions are managing</h3>
                                <h3>their lives. Harness the power of shared experiences.</h3>

                                <h3 class="mt-4">Join groups of your choice. There are no charges or fees.</h3>
                            </div>
                        </div>
                        <!-- FOR MOBILE  -->
                        <div class="text-align text-center banner-sub-content d-block d-lg-none">
                            <div class="joinPatientGroupText">
                                <h3 class="mb-0">Find strength in communities.</h3>
                                <h3 class="mb-0">Get answers to your health </h3>
                                <h3 class="mb-0">queries. Overcome your fears</h3>
                                <h3>and anxieties.</h3>

                                <h3 class="mt-4 mb-0">Learn how people with similar</h3>
                                <h3 class="mb-0">health conditions are managing</h3>
                                <h3>their lives.</h3>

                                <h3 class="mt-4 mb-0">Harness the power of shared</h3>
                                <h3 class="mb-0">experiences.</h3>

                                <h3 class="mt-4 mb-0">Join groups of your choice.</h3>
                                <h3>There are no charges or fees.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="banner-two2 img-banner-white">
                <div class="container text-center text-align">
                    <!-- FOR DESKTOP  --> 
                    <div class="choosePatientGroupText subPatientGroupTop d-none d-lg-block">
                        <h2 class="subPatientGroupTop mb-0">Select a disorder type to join the</h2>
                        <h2>relevant patient groups</h2>
                        <!-- <h2>group that is of interest to you</h2> -->
                    </div>
                    <!-- FOR MOBILE  -->
                    <div class="choosePatientGroupText subPatientGroupTop d-block d-lg-none">
                        <h2 class="subPatientGroupTop mb-0">Select a disorder type</h2>
                        <h2 class="mb-0">to view the related</h2>
                        <h2>patient groups</h2>
                        <!-- <h2>group that interest you</h2> -->
                    </div>
                    <div class="mt-4">
                        <select class="form-control JoinPatient" name="select" id="select" v-model="selected" @change="getPatintGroup($event)">
                            <option value="1">Select disorder</option>
                            <!-- <option value='all'>All</option> -->
                            <option v-for="index in allTopics" :key="index">{{ index.name }}</option>
                        </select>
                    </div>
                    <div v-if="filteredSpecilities.length">
                    <div class="row box-items-space" >
                        <div class="col-opt-box" v-for="index in filteredSpecilities" :key="index" @click="signupgeneral(index)">
                            <div class="col-fix mt-3 color-bg" >
                                <div class="btn-text-bg">
                                    <div class="box-text-bg">
                                        <!-- <div class="box-text-bg">{{ index.shortname }}</div> -->
                                        <div class="eyes-text">
                                            <p>{{ index.group_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-action-background">Join group</button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div v-else-if="selected !=='1' && !filteredSpecilities.length" class="mt-5">
                <h4 style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)">No groups currently available</h4>
            </div>
                </div>
            </section>
        </div>
    </div>
    <MobileNavbar></MobileNavbar>
</template>
<script>
import MobileNavbar from "../common-mobile-footer.vue"
import axios from "axios"
import { defineComponent } from "vue";
export default defineComponent({
    components: {
        MobileNavbar
    },
    el: '#hide',
    data() {
        return {
            seen: true,
            currentPath: "",
            allTopics: [],
            selected:'1',
            objSpeclities:{},
            filteredSpecilities:[]
        }
    },
    created: function () {
        this.currentPath = this.$route.path.split('/')[2]
        this.getAllTopics()
    },
    mounted(){
        this.getAllTopics() ;
    },
    methods: {
        async getAllTopics() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  
        const response = await axios.get(`${BASE_API_URL}/topics`);

        if (response?.data) {
            // Filter out the object with id: 24
            this.allTopics = response.data.filter(topic => topic.id !== 24);
        }
        },
        getPatintGroup(event){
        const topics= this.allTopics.filter(item => item.name == event.target.value);
        topics.map((item)=>{
        this.objSpeclities={
            check :item.check,
            id:item.id,
            name:item.name
        }
        })
        if(event.target.value!= 1){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    axios
    .get(`${BASE_API_URL}/patientgroup/specialities?disorder_id=${this.objSpeclities.id}`)
    .then((response) => {
        this.filteredSpecilities=[];
        if (response?.data) {
                response.data.map((patientGroup)=>{
            if(patientGroup.status == true){
                this.filteredSpecilities.push(patientGroup)
            }
        })
       
        }})
    }else if(event.target.value == 1){
                this.objSpeclities =false
        this.filteredSpecilities=[];
    }
},
    signupgeneral(data) {
        localStorage.removeItem('topic')
        localStorage.removeItem('disorder')
        const patientGroupPayload={
            patientGroupData : JSON.stringify(data),
            routeName : 'joinpatientgroups',
            card:'public',
            publicPatientGroupId:JSON.stringify(3)
        }
        window.scrollTo(0, 0);
        this.$router.push({ path: '/signup', query: patientGroupPayload });
    },
        accessReliableInformation() {
            window.scrollTo(0, 0);
      this.$router.push('/public/patientresources');
        },
        requestConsultation() {
            window.scrollTo(0, 0);
      this.$router.push('/public/requestconsultation');
        },
        joinDoctorsCommunity() {
            window.scrollTo(0, 0);
      this.$router.push('/public/doctorcommunities');
        },
        searchClinic() {
            window.scrollTo(0, 0);
      this.$router.push('/public/searchclinic');
        },
   

    }
})

</script>
<style>
select.form-control.JoinPatient {
    width: 400px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-bottom: 5px;

}

.banner-sub-content {
    margin-top: 20px;
}

nav.three-nav1 {
    float: right;
    position: fixed;
    right: -3px;
    background: transparent;
}

nav.three-nav1:hover {
    transform: perspective(400px) rotateY(1deg);
}

nav.three-nav1 ul.three-nav1 {
    text-align: center;
    margin-top: 30px;
}

@media screen and (max-width:992px) {
    nav.three-nav1 ul.three-nav1 {
        text-align: center;
        margin-top: 0px
    }
}

nav.three-nav1 ul.three-nav1 li.three-nav1 {
    position: relative;
    width: 71px;
    cursor: pointer;
    background: cadetblue;
    text-transform: uppercase;
    transition: all .4s ease-out;
    list-style: none;
}

nav.three-nav1 ul.three-nav1 li:after {
    position: absolute;
    background: white;
    color: crimson;
    top: 0;
    left: 0px;
    width: 70px;
    height: 100%;
    opacity: .5;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: 0 100%;
    transition: all .4s ease-out;
}

nav.three-nav1 ul.three-nav1 li:nth-child(1):after {
    line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(2):after {
    line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(3):after {
    line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(4):after {
    line-height: 70px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(5):after {
    line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:hover {
    transform: translateX(0px);
    background-color: #fff;
}

nav.three-nav1 ul.three-nav1 li:hover:after {
    opacity: 1;
    transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav1 ul.three-nav1 li>div {
    display: inline-block;
    padding: 25px 0;
    background: transparent;
}

nav.three-nav1 ul.three-nav1 li.three-nav1 div {
    position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu1 {
    list-style: none;
    position: relative;
    left: 35px;
    bottom: 20px;
}



.eyes-text p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-text-bg {
    font-size: 45px;
    color: #fff;
}

.row.box-items-space {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 25% 25% 25%;
    justify-content: center;
    grid-gap: 20px;
}

.col-opt-box {
    width: 100%;
    display: grid;
    justify-content: center;
    cursor: pointer;
}

.col-fix.mt-3.color-bg {
    border: 2px solid #00979e;
    width: 235px;
    /* margin-bottom: 30px; */
    background-color: #00979e;
}



.btn-text-bg {
    text-align: center;
    padding: 15px;
    color: #fff;
    background-color: #00979e;
}



button.btn.btn-action-background {
    background: #fff;
    color: #00979e;
    width: 100%;
    border-radius: inherit;
    border: 2px solid #00979e;
}

.choosePatientGroupText h2 {
    color: #000;
    text-align: center;
}

.subPatientGroupTop {
    margin-top: 3rem;
}


.heading-patient-groups h1 {
    text-align: center;
    margin-top: 20px;
    color: #000000;
    font-weight: bold;
}


section.banner-two2.img.banner {
    margin-top: 4.5rem;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/cards-banner7.png");
    background-size: 3500px 593px;
    padding: 30px 0px 50px 0px;

}

section.banner-two2.img-banner-white {
    background-color: #fff;
    background-repeat: no-repeat;
}

.navbar-light .navbar-nav .nav-link {
    color: #00979e;
}
@media screen and (max-width:1200px) {
    .row.box-items-space {
        grid-template-columns: 30% 30% 30%;
    }

}

@media screen and (max-width:992px) {
    select.form-control.JoinPatient {
        width: 300px;
        font-size: 18px;
    }

    .col-fix.mt-3.color-bg {
        width: 210px;
    }

    .row.box-items-space {
        grid-gap: 70px;
    }
}

@media screen and (max-width:991px) {
    .navbarmobileFix {
        margin-bottom: 7rem;
    }

    section.banner-two2.img.banner {
        margin-top: 0rem;
    }

    .choosePatientGroupText h2 {
        font-size: 28px;
    }

    .heading-patient-groups h1 {
        font-size: 33px;
    }

    .joinPatientGroupText h3 {
        font-size: 22px;
    }

    .subPatientGroupTop {
        margin-top: 2rem;
    }
}

@media screen and (max-width:768px) {
    select.form-control.JoinPatient {
        width: 300px;
        font-size: 16px;
    }

    .row.box-items-space {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
}

@media screen and (max-width:576px) {
    .heading-patient-groups h1 {
        font-size: 25px;
        /* margin-top: 10px; */
    }

    .joinPatientGroupText h3 {
        font-size: 17px;
    }

    .choosePatientGroupText h2 {
        font-size: 21px;
    }

    .subPatientGroupTop {
        margin-top: 1.5rem;
    }

    section.banner-two2.img.banner {
        padding: 10px 0px 27px 0px;
    }
}

@media screen and (max-width:550px) {

    .row.box-items-space {
        margin-top: 10px;
    }

    select.form-control.JoinPatient{
        width: 200px;
        font-size: 14px;
    }
}

@media screen and (max-width:470px) {
    .row.box-items-space {
        grid-gap: 0%
    }

    .col-fix.mt-3.color-bg {
        width: 133px;
    }

    .box-text-bg {
        font-size: 26px;
    }

    .btn-text-bg {
        padding: 4px;
    }

    .eyes-text p {
        font-size: 16px;
    }
}
</style>